<template>
  <div class="mt-4 container-fluid">
    <div class="d-flex justify-content-between mb-4">
      <h3>Commissions - Configurations</h3>
    </div>
    <b-card no-body>
      <div class="d-flex">
        <nav class="payout-nav">
          <router-link to="/commissions/deals">
            <div :class="['item mt-1', {'item-selected': isActive('/deals')}]">
              {{ translations.deals.nav_title }}
            </div>
          </router-link>
          <router-link to="/commissions/rvps">
            <div :class="['item mt-1', {'item-selected': isActive('/rvps')}]">
              {{ translations.rvps.nav_title }}
            </div>
          </router-link>
        </nav>
        <b-container class="mt-4 overflow-auto" fluid>
          <slot/>
        </b-container>
      </div>
    </b-card>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'ConfigurationLayout',
  data() {
    return {
      translations: translations.commissions,
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>
<style scoped>
.payout-nav {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0.75rem 1.25rem;
  background-color: #e7eaf0;

  .item {
    padding: 0.5rem 1rem;
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .item-selected {
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
  }
}
</style>
